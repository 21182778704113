import { autoinject } from "aurelia-dependency-injection";
import { DialogController } from "aurelia-dialog";

@autoinject
export class MoveRole {
    constructor(protected controller: DialogController) {

    }

    params;
    activate(params) {
        this.params = params;
    }
}
